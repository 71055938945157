
export const transformDate = (date: string): string => {
    const transform: Array<string> = date.split("/");
    return `${transform[2]}-${transform[1]}-${transform[0]}`
}

export const transformDateRequest = (date: string): string => {
    const transform: Array<string> = date.split("/");
    return `${transform[2]}/${transform[1]}/${transform[0]}`
}

export const getTimeFromDate = (date: string): string => {
    return date.split("T")[1];
}

export const getCurrentDate = (separator = ''): string => {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? 0: ""}${date}`
}

export const militaryTimeToAmPm = (time: number): string => {
    return time - 12 >= 0 && time - 12 !== 12 ? (time - 12 === 0 ? 12 : time - 12) + "PM" : (time - 12 === 12 ? 12 : (12 - (time - 12) * -1)) + "AM";    
}