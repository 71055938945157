import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthProv";
import RequireAuth from "./pages/RequireAuth";
import Login from "./pages/Login";
import SiteMissing from "./pages/SiteMissing";
import CreateUser from "./pages/CreateUser";
import PersistLogin from "./pages/PersistLogin";
import Home from "./pages/Home";
import { PageProvider } from "./context/PageProv";
import ManageUsers from "./pages/ManageUser";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import 'dayjs/locale/en-gb';
import './index.css'

const App: React.FC = () => {

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <BrowserRouter>
          <AuthProvider>
            <PageProvider>
              <Routes>
                <Route path="/login" element={<Login />} />

                <Route element={<PersistLogin />}>
                  <Route element={<RequireAuth />}>
                    <Route path="/home" element={<Home />} />
                    <Route path="/admin/createUser" element={<CreateUser />} />
                    <Route path="/manage/:user" element={<ManageUsers />} />
                  </Route>
                </Route>

                <Route path="/*" element={<SiteMissing />} />

              </Routes>
            </PageProvider>
          </AuthProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </div>
  );


}



export default App;