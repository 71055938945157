import React, { useState, useEffect, useReducer, use } from "react";
import toast, { Toaster } from "react-hot-toast";
import { privateAxios } from "../api/axios";
import useAuth from "../hooks/useAuth";
import dayjs, { Dayjs } from "dayjs";
import { Area, Tooltip as ChartTooltip, ComposedChart, ReferenceLine, XAxis, YAxis, CartesianGrid, Bar } from "recharts";
import { getLang, lang } from "../api/lang";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle
} from "../components/ui/card"
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
    CarouselApi,
} from "../components/ui/carousel"
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../components/ui/tooltip"
import { transformDate } from "../api/dateTransformerer";
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { cn } from "../lib/utils"
import { Button } from "../components/ui/button"
import { Calendar } from "../components/ui/calendar"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "../components/ui/popover"
import { date } from "zod";
import { hi } from "date-fns/locale";

const Data = () => {

    const { auth } = useAuth();
    const [data, setData] = useState([{}]);
    const [date, setDate] = useState<Date>();
    const [latestData, setLatestData] = useState([{}]);
    const [devices, setDevices] = useState([]);
    const [dates, setDates] = useState([]);
    const [mm, setMM] = useState([{ "m/min": 0 }]);
    const [latestMM, setLatestMM] = useState([{ "m/min": 0 }]);
    const [avgSpeed, setAvgSpeed] = useState(0);
    const [force, forceUpdate] = useReducer(x => x + 1, 0);
    const [dateIndex, setDateIndex] = useState(-1);
    const [entryTime, setEntryTime] = useState(0);
    const [latestTime, setLatestTime] = useState(0);
    const [api, setApi] = useState<CarouselApi>();
    const [loaded, setLoaded] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [strokeColor, setStrokeColor] = useState(localStorage.getItem("theme") === "dark" ? `#ffffff` : `#000000`);
    const [errorTimes, setErrorTimes] = useState([{}]);
    const [errors, setErrors] = useState([{}]);

    const updateDate = async (id: string) => {
        setDateIndex(0);
        sessionStorage.setItem("date", id);
        forceUpdate();
    }

    const fetchErrors = async () => {
        setErrors([{}]);
        try {
            const response = await privateAxios.post("/devices/errors",
                JSON.stringify({ device: sessionStorage.getItem("device"), date: sessionStorage.getItem("date") }), {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
            if (!response?.data?.length) return;
        } catch (err) {
            toast.error(`Error code ${err.response?.status}.\n${err}`);
        }
    };

    const fetchData = async () => {
        try {
            const response = await privateAxios.post("/devices/data",
                JSON.stringify({ device: sessionStorage.getItem("device"), date: sessionStorage.getItem("date") }), {
                headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${auth?.accessToken}` },
                withCredentials: true
            });
            if (!response?.data?.length) {
                setData([{}]);
                return;
            };
            setData(response.data);
            var mmData: Array<any> = [];
            var totalSpeed = 0;
            const lowestTime: number = response.data[0]['date'] === undefined ? 7 : parseInt(response.data[0]["date"].split(":")[0]);
            const lowestTimeString: string = response.data[0]['date'] === undefined ? "07:00:00.000" : response.data[0]["date"];
            const highestTime: number = response.data[response.data.length - 1]['date'] === undefined ? 7 : parseInt(response.data[response.data.length - 1]["date"].split(":")[0]);
            const highestTimeString: string = response.data[response.data.length - 1]['date'] === undefined ? "07:00:00.000" : response.data[response.data.length - 1]["date"];
            setLatestTime(parseInt(highestTimeString.split(":")[0]));
            const highestTimeUnix = new Date(`${transformDate(sessionStorage.getItem("date") as string)}T${highestTimeString}`).getTime();
            const lowestTimeUnix: number = new Date(`${transformDate(sessionStorage.getItem("date") as string)}T${lowestTimeString}`).getTime();
            const highestTimePredictedUnix: number = new Date(`${transformDate(sessionStorage.getItem("date") as string)}T${lowestTimeString}`).setHours(lowestTime + 10);
            if (errorTimes.length && errorTimes[0]['err'] != undefined) {
                let errors = [];
                for (var i = 0; i < errorTimes.length; i++) {
                    const reason = errorTimes[i]["err"][2];
                    const start: number = new Date(`${transformDate(sessionStorage.getItem('date') as string)}T${errorTimes[i]["err"][0]}`).getTime();
                    const end: number = new Date(`${transformDate(sessionStorage.getItem('date') as string)}T${errorTimes[i]["err"][1]}`).getTime();
                    const startPercent: number = (start - lowestTimeUnix) / (highestTimePredictedUnix - lowestTimeUnix) * 100;
                    const endPercent: number = (end - lowestTimeUnix) / (highestTimePredictedUnix - lowestTimeUnix) * 100;
                    errors.push({ "err": [startPercent, endPercent], "reason": reason });
                }
                setErrors(errors);
            }
            for (var i = 0; i < response?.data.length; i++) {
                if (response.data[i]["m/min"] !== -1) {
                    const split = response.data[i]["date"].split(":");
                    mmData.push({ "m/min": response.data[i]["m/min"], date: `${split[0]}:${split[1]}` });
                    totalSpeed += response.data[i]["m/min"];
                }
                if (!response.data[i + 1]) continue;
                const splitNext = response.data[i + 1]["date"].split(":");
                // Fills missing data with 0s
                const minNext = parseInt(splitNext[1]);
                const hourNext = parseInt(splitNext[0]);
                const secondNext = parseInt(splitNext[2]);
                const now: Date = new Date(`${transformDate(sessionStorage.getItem("date") as any)}T${response.data[i]["date"]}`);
                const next: Date = new Date(`${transformDate(sessionStorage.getItem("date") as any)}T${hourNext < 10 ? "0" : ""}${hourNext}:${minNext < 10 ? "0" : ""}${minNext}:${secondNext < 10 ? "0" : ""}${secondNext}`);
                const timeDiff: number = next.getTime() - now.getTime();
                const minDiff: number = Math.round(timeDiff / (1000) / 60) || 0;
                if (minDiff > 0) {
                    for (var h = 0; h < minDiff; h++) {
                        now.setMinutes(now.getMinutes() + 1);
                        mmData.push({ "m/min": 0, date: `${now.getHours() < 10 ? 0 : ""}${now.getHours()}:${now.getMinutes() < 10 ? 0 : ""}${now.getMinutes()}` });
                    }
                }
            }
            setAvgSpeed(totalSpeed / mmData.filter(v => v['m/min'] != 0).length);
            setEntryTime(lowestTime);
            var date: Date = new Date(`${transformDate(sessionStorage.getItem("date") as any)}T${response.data[0]["date"]}`);
            const offset = 1 - ((mmData.length / 600) / 1.8);
            for (var i = 0; i < 600 * offset; i++) {
                date.setMinutes(date.getMinutes() + 1);
                mmData.push({ date: "", "m/min": 0 });
            }

            setMM(mmData);
        } catch (err) {
            toast.error(`Error code ${err.response?.status}.\n${err}`)
        }
    }

    const fetchDevices = async () => {
        try {
            const response = await privateAxios.post("/devices",
                JSON.stringify({}), {
                headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${auth?.accessToken}` },
                withCredentials: true
            });
            if (!response?.data?.length) return;
            sessionStorage.removeItem("device");
            sessionStorage.setItem("device", response?.data[0]);
        } catch (err) {
            toast.error(`Error code ${err.response?.status}.\n${err}`);
        }
    }

    const fetchLatest = async () => {
        try {
            const response = await privateAxios.post("/devices/data",
                JSON.stringify({ device: sessionStorage.getItem("device"), date: sessionStorage.getItem("date"), latest: true }), {
                headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${auth?.accessToken}` },
                withCredentials: true
            });
            if (response.data.length <= 0) return;
            var mmData: any = [];
            for (var i = 0; i < response?.data?.length; i++) {
                if (response.data[i]["m/min"] !== -1) {
                    mmData.push({ "m/min": response.data[i]["m/min"], date: response.data[i]["date"] });
                }
            }
            setLatestData(response?.data);
            const today: Dayjs = dayjs();
            const date: String = response.data[response.data.length - 1]["date"];
            const dateTaken = dayjs(`${response.data[response.data.length - 1]["dateTaken"]}T${date}`);
            setLatestMM(today.diff(dateTaken, "minutes") > 1 ? [{ "m/min": 0, date }] : mmData);
        } catch (err) {
            if (err.response?.status == undefined) return;
            toast.error(`Error code ${err.response?.status}.\n${err}`);
        }
    }

    const fetchDates = async () => {
        try {
            const response = await privateAxios.post("/devices/selection",
                JSON.stringify({ device: sessionStorage.getItem("device") }), {
                headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${auth?.accessToken}` },
                withCredentials: true
            });
            const data = response?.data;
            setDates(data);
            if (dateIndex === -1) {
                if (response.data.length > 0) {
                    setDateIndex(response.data.length - 1);
                    sessionStorage.setItem("date", response.data[response.data.length - 1]);
                    forceUpdate();
                    if (api) {
                        api.scrollTo(response.data.length - 1, true);
                    }
                }
            }
        } catch (err) {
            toast.error(`Error code ${err.response?.status}.\n${err}`)
        }
    }

    useEffect(() => {
        if (date) {
            const dateString: string = format(date, "dd/MM/yyyy");
            updateDate(dateString);
            if (api) {
                api.scrollTo(dates.indexOf(dateString), true);
            }
        }
    }, [date]);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }

    }, []);

    useEffect(() => {
        setInterval(() => fetchData(), 60 * 1000);
        setInterval(() => fetchLatest(), 60 * 1000);
    }, [])

    useEffect(() => {
        if (!api) {
            return;
        }

        api.on("select", () => {
            updateDate(api.containerNode().innerText.split("\n")[api.internalEngine().index.get()]);
            forceUpdate();
        });

    }, [api]);

    useEffect(() => {

        const fetchAllData = async () => {
            //setErrorTimes([{ "err": ["10:28", "11:50", "Test"] }]);
            await fetchDevices();
            await fetchDates();
            await fetchErrors();
            await fetchLatest();
            await fetchData();
            console.log(errors);
        };

        fetchAllData();

        if (!api || loaded || dates.length === 0) {
            return;
        }
        api.scrollTo(dates.length - 1, true);
        setLoaded(true);

    }, [force])

    const getErrorPos = (input: Array<any>): Array<any> => {
        if (input['err'] == undefined) return [0, 0];
        const start = 85 + (input["err"][0] / 100) * (width - 160);
        const end = 85 + (input["err"][1] / 100) * (width - 160) - start;
        return [start, end];
    }

    const isDayAvailable = (day: Date): boolean => {
        return dates.includes(format(day, "dd/MM/yyyy"));
    }

    const dataRenderer = (
        <div>
            <Toaster position="bottom-right" />

            <div style={{
                paddingLeft: "80px",
            }}>

                <Popover>
                    <PopoverTrigger asChild>
                        <Button
                            variant={"outline"}
                            className='w-[75px]'>
                            <CalendarIcon className="mr-1 h-4 w-4" />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                        <Calendar
                            mode="single"
                            selected={date}
                            onSelect={setDate}
                            disabled={(day) => { return !isDayAvailable(day) }}
                            initialFocus />
                    </PopoverContent>
                </Popover>

                <Carousel className="w-[250px]" setApi={setApi} opts={{
                    loop: true,
                }}>
                    <CarouselContent>
                        {dates?.length ? dates.map((date: string, index: number) => {
                            return (
                                <CarouselItem key={index}>
                                    <Card>
                                        <CardContent className="flex aspect-rectangle items-center justify-center p-2">
                                            <span>{date}</span>
                                        </CardContent>
                                    </Card>
                                </CarouselItem>
                            )
                        }) : "No data available"}
                    </CarouselContent>
                    <CarouselPrevious />
                    <CarouselNext />
                </Carousel>

                <Card className="w-[250px]">
                    <CardHeader style={{
                        paddingBottom: "10px"
                    }}>
                        <CardTitle style={{
                            fontSize: "15px"
                        }}>
                            {lang[getLang()]["selection"]["currentSpeed"]}
                        </CardTitle>
                        <CardDescription>
                            <text>
                                {lang[getLang()]["selection"]["m/min"]}
                            </text>
                        </CardDescription>
                    </CardHeader >
                    <CardContent style={{
                        fontSize: "30px",
                        paddingBottom: "10px"
                    }} className="items-center">
                        <text x={100} y={100} textAnchor="middle" dominantBaseline={"middle"} fontSize={50}>
                            {latestMM?.length ? latestMM[latestMM.length - 1]["m/min"] : 0}
                        </text>
                    </CardContent>
                </Card>

            </div>

            <ComposedChart data={mm} margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: -25
            }} width={width - 50} height={170} className="position-relative p-1">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis type="number" />
                <Area type="monotone" dataKey="m/min" fill="#3830D5" stroke={strokeColor} />
                <ReferenceLine ifOverflow="extendDomain" y={0} />
                <ChartTooltip
                    labelStyle={{
                        color: `#000000`
                    }}
                    itemStyle={{
                        color: `#000000`
                    }}
                />
            </ComposedChart>

            <text style={{
                paddingLeft: `80px`,
            }} className="position-relative">
                {entryTime}
            </text>
            <text style={{
                paddingLeft: `${width / 2 - 95}px`
            }} className="position-relative">
                {mm?.length ? mm[Math.round((mm.length - 1) / 2)]["date"] ? mm[Math.round((mm.length - 1) / 2)]["date"].toString().split(":")[0] : 13 : 13}
            </text>
            <text style={{
                paddingLeft: `${width / 2 - 100}px`,
            }} className="position-relative">
                {entryTime + 10 >= 24 ? entryTime + 10 - 24 : entryTime + 10}
            </text>

            <ComposedChart data={[data[data.length - 1]]} margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
            }} layout="vertical" width={width - 50} height={90} className="position-relative p-1">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis type="category" />
                <Bar type="monotone" dataKey="total" fill="#8884d8" />
                <ReferenceLine ifOverflow="extendDomain" x={auth.maxMM * 600 / 2.5} />
                <ChartTooltip />
            </ComposedChart>
            <p className="position-relative start-5 w-20">
                {lang[getLang()]["selection"]["total"]}: {data[data.length - 1]["total"]}
            </p>
            <p className="position-relative start-5 w-20">
                {lang[getLang()]["selection"]["totalPlanks"]}: {data[data.length - 1]["totalPlanks"]}
            </p>
            <p className="position-relative start-5 w-20">
                {lang[getLang()]["selection"]["totalWorkTime"]}: {latestTime - entryTime}h
            </p>
            <p className="position-relative start-5 w-20">
                {lang[getLang()]["selection"]["workTime"]}: {(mm.filter(v => v['m/min'] != 0).length / 60).toFixed(2)}h
            </p>
            <p className="position-relative start-5 w-20">
                {lang[getLang()]["selection"]["idleTime"]}: {(mm.filter(v => v['date'] !== '' && v['m/min'] == 0).length / 60).toFixed(2)}h
            </p>
            <p className="position-relative start-5 w-20">
                {lang[getLang()]["selection"]["avgSpeed"]}: {avgSpeed.toFixed(2)} {lang[getLang()]["selection"]["m/min"]}
            </p>

            {errors.length && errors.filter(v => v['err'] != undefined).map((error: Array<any>, index: number) => {
                return (
                    <TooltipProvider key={index}>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <div className={`container-sm position-absolute`} style={{
                                    left: `${getErrorPos(error)[0]}px`,
                                    width: `${getErrorPos(error)[1]}px`,
                                    minWidth: `1px`,
                                    boxSizing: `border-box`,
                                    maxWidth: `${width - 160}px`,
                                    top: `350px`,
                                    height: `25px`,
                                    opacity: 0.5,
                                    backgroundColor: `#C35048`
                                }}></div>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>{error['reason']}</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                )
            })}

        </div>
    );

    return (
        <div>
            {data?.length ? dataRenderer : ""}
        </div>
    )

}

export default Data;