import React, { useState, useContext } from "react";
import axiosPrivate from "../hooks/axiosPrivate";
import useAuth from "../hooks/useAuth";
import RefreshToken from "../hooks/refreshToken";
import toast, { Toaster } from "react-hot-toast";
import { privateAxios } from "../api/axios";

const CreateUser = () => {
    const axios = axiosPrivate();
    const { auth } = useAuth();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [company, setCompany] = useState("");
    const [admin, setIsAdmin] = useState(false);
    const [companyAdmin, setCompanyAdmin] = useState(false);
    const [ip, setIp] = useState("");
    const [minLength, setMinLength] = useState(1000);
    const [maxLength, setMaxLength] = useState(6000);
    const [maxMM, setMaxMM] = useState(100);
    const handleSumbit = async (event) => {
        event.preventDefault();

        try {
            const response = await privateAxios.post("/register",
                JSON.stringify({ username, password, company, isAdmin: admin, ip, companyAdmin: companyAdmin, minLength, maxLength, maxMM}), {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }); 
        } catch (err) {
            toast.error(`Error code ${err.response?.status}. \nPlease contact an ODA Instruments representative!`)
        }
    }

    const renderRegisterUser = (
        <div>
            <div className="position-absolute top-50 start-50 translate-middle form container-sm bg-secondary text-light w-auto h-30 p-4 rounded-2">
                <div className="title">
                    <h5>Create Account</h5>
                </div>
                <form onSubmit={handleSumbit}>
                    <div className="input-container">
                        <div>
                            <label>Username</label>
                        </div>
                        <input type="text" name="username" placeholder="John" onChange={e => setUsername(e.target.value)} required />
                    </div>
                    <div>
                        <div className="input-container">
                            <div>
                                <label>Password</label>
                            </div>
                            <input type="text" name="password" placeholder="Password123!@#$" onChange={e => setPassword(e.target.value)} required />
                        </div>
                    </div>
                    <div>
                        <div className="input-container">
                            <div>
                                <label>Company</label>
                            </div>
                            <input type="text" name="company" placeholder="ODA" onChange={e => setCompany(e.target.value)} required />
                        </div>
                    </div>
                    <div>
                        <div className="input-container">
                            <div>
                                <label>IP range</label>
                            </div>
                            <input type="text" name="ip" placeholder="93.2-2" onChange={e => setIp(e.target.value)} required />
                        </div>
                    </div>
                    <div>
                        <div className="input-container">
                            <div>
                                <label>Minimum Length for a board in mm</label>
                            </div>
                            <input type="number" name="min" placeholder="1000" onChange={e => setMinLength(parseInt(e.target.value))} required />
                        </div>
                    </div>
                    <div>
                        <div className="input-container">
                            <div>
                                <label>Maximum Length for a board in mm</label>
                            </div>
                            <input type="number" name="max" placeholder="6000" onChange={e => setMaxLength(parseInt(e.target.value))} required />
                        </div>
                    </div>
                    <div>
                        <div className="input-container">
                            <div>
                                <label>Maximum Meters / Min</label>
                            </div>
                            <input type="number" name="maxMM" placeholder="100" onChange={e => setMaxMM(parseInt(e.target.value))} required />
                        </div>
                    </div>
                    <div>
                        <div className="input-container">
                            <div>
                                <label>Is user a Web Admin</label>
                            </div>
                            <input type="checkbox" name="isAdmin" placeholder="Admin" onChange={e => setIsAdmin(!admin)} />
                        </div>
                    </div>
                    <div className="button-container p-3">
                        <button type="submit" className="btn position-relative start-50 translate-middle-x btn-light">Create Account</button>
                    </div>
                </form>
            </div>
        </div>
    );

    const renderAdminPanel = (
        <div>
            <Toaster position="top-center" reverseOrder={true} />
            {auth?.isAdmin ? renderRegisterUser : "Invalid Credentials"}
        </div>
    );

    return (
        <div className="login">
            <div className="login-form">
                {renderAdminPanel}
            </div>
        </div>
    )

}

export default CreateUser;