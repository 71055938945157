import axios, { privateAxios } from "../api/axios";
import useAuth from "./useAuth";

const RefreshToken = () => {
    const { auth, setAuth } = useAuth();

    const refresh = async () => {
        const response = await privateAxios.post("/token");
        setAuth(p => {
            return { username: response.data.username, isAdmin: response.data.isAdmin, accessToken: response.data.accessToken, companyAdmin: response.data.companyAdmin, maxMM: response.data.maxMM }
        });
        return response.data.accessToken;
    }
    return refresh;
}

export default RefreshToken;