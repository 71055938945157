import React, { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { Link } from "react-router-dom";
import axios, { privateAxios } from "../api/axios";
import { getLang, lang } from "../api/lang";

const Admin = () => {

    const { auth } = useAuth();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await privateAxios.post("/admin/users",
                JSON.stringify({}), {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
            setUsers(response.data);
        }
        fetchUsers();
    }, []);

    return (
        <div>
            <div>
                <h5>{lang[getLang()]["selection"]["users"]}</h5>
                {users?.length ? users.map((u, i) => <p key={i}>
                {<Link to={`/manage/${u}`} state={{user: u}}>{u}</Link>}
                </p>) : <p>No Users</p>}
            </div>
            <h5>{auth.isAdmin && <Link to={"/admin/createuser"}>Create User</Link>}</h5>
        </div>
    )

}

export default Admin;