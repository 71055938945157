import React, { createContext, useState } from "react";

interface pageContext {
    setPage,
    page
}

const PageContext = createContext<pageContext>({ setPage: null, page: null });

export const PageProvider = ({ children }) => {
    const [page, setPage] = useState({});

    return (
        <PageContext.Provider value={{ setPage, page }}>
            {children}
        </PageContext.Provider>
    )
}

export default PageContext;