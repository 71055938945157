import axios from 'axios';

const isProduction = process.env.NODE_ENV === 'production';
const baseURL = isProduction ? 'https://ergotaksmart.com:8443/api' : 'http://localhost:5000/';

export default axios.create({
    baseURL
});

export const privateAxios = axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});