import React, { useEffect, useReducer, useState } from "react";
import useAuth from "../hooks/useAuth";
import '../scss/_utilities.scss';
import Admin from "./Admin";
import usePage from "../hooks/usePage";
import { ButtonBase, ButtonGroup, IconButton, SvgIconClasses } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import ListIcon from "@mui/icons-material/List";
import LightIcon from "@mui/icons-material/WbSunny";
import DarkIcon from '@mui/icons-material/NightsStay';
import Data from "./Data";
import { Dropdown, MenuButton as BaseMenuButton, MenuItem as BaseMenuItem, Menu, menuItemClasses } from "@mui/base";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import { styled } from '@mui/system';
import { getLang, lang } from "../api/lang";
import { getCurrentDate, transformDateRequest } from "../api/dateTransformerer";
import { privateAxios } from "../api/axios";
import { setCookie } from "../api/cookieUtil";

const Home = () => {

    const { setAuth, auth } = useAuth();
    const { page, setPage } = usePage();
    const [language, setLang] = useState(localStorage.getItem("lang") || "en");
    const [force, forceUpdate] = useReducer(x => x + 1, 0);

    const handleSumbit = async (e, o) => {
        e.preventDefault();
        setPage(o);
    }

    const handleLangChange = async (e, o) => {
        window.location.reload();
        await localStorage.setItem("lang", o.lang);
        forceUpdate();
    }

    const handleLogout = async (e) => {
        e.preventDefault();
        await privateAxios.get("/logout")
        setAuth({});
        localStorage.clear();
        sessionStorage.clear();
    }

    useEffect(() => {
        // TODO
        if (localStorage.getItem("theme") === "dark")
            document.body.classList.add("dark");
        sessionStorage.setItem("date", transformDateRequest(getCurrentDate("/")));
        setPage([<Data />]);
        if (!localStorage.getItem("lang")) {
            localStorage.setItem("lang", "EN");
            setLang("EN");
        }
    }, [])

    useEffect(() => {
        setLang(localStorage.getItem("lang") as any);
    }, [force]);

    const currentTheme = (): String => {
        return localStorage.getItem("theme") || "dark";
    }

    const currentThemeButton = (): React.ElementType => {
        return currentTheme() === "dark" ? LightIcon : DarkIcon;
    }

    return (
        <div>
            <div className="container-fluid bg-primary">
                <div className="p-1 position-absolute end-0">
                    <text>
                        Welcome, {auth.username}!
                    </text>
                    <IconButton color={"warning"} onClick={(e) => handleLogout(e)}>
                        <LogoutIcon />
                    </IconButton>
                </div>
                <ButtonGroup>
                    <Dropdown>
                        <BaseMenuButton>
                            <IconButton>
                                <ListIcon />
                            </IconButton>
                        </BaseMenuButton>
                        <Menu slots={{ listbox: Listbox }}>
                            <BaseMenuItem className="p-2" color="info" onClick={(e) => handleSumbit(e, [<Data />])}>
                                {lang[getLang()]["selection"]["data"]}
                            </BaseMenuItem>
                            {auth.isAdmin ?
                                <BaseMenuItem className="p-2" color="info" onClick={(e) => handleSumbit(e, [<Admin />])}>
                                    {lang[getLang()]["selection"]["adminPanel"]}
                                </BaseMenuItem>
                                : ""}
                        </Menu>
                    </Dropdown >
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <IconButton>
                                {React.createElement(currentThemeButton(), {
                                    onClick: () => {
                                        localStorage.setItem("theme", currentTheme() === "dark" ? "light" : "dark");
                                        forceUpdate()
                                        
                                        document.body.classList[0] === "dark" && currentTheme() === "light" ? document.body.classList.remove("dark") : document.body.classList.add("dark");
                                    }
                                })}
                            </IconButton>
                        </DropdownMenuTrigger>
                    </DropdownMenu>

                    <Dropdown>
                        <BaseMenuButton>
                            {language}
                        </BaseMenuButton>
                        <Menu slots={{ listbox: Listbox }}>
                            {lang.map((e, i) =>
                                <BaseMenuItem key={i} className="p-2" color="info" onClick={(h) => handleLangChange(h, e)}>{e.lang}</BaseMenuItem>
                            )}
                        </Menu>
                    </Dropdown>

                </ButtonGroup>
            </div>
            <div className={`position-absolute top-15 start-1`}>
                {page?.length ? page[0] : ""}
            </div>

        </div >
    )

}

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#99CCF3',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E6',
    700: '#0059B3',
    800: '#004C99',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Listbox = styled('ul')(
    ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    min-width: 200px;
    border-radius: 12px;
    overflow: auto;
    outline: 0px;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
    z-index: 1;
    `,
);

const MenuItem = styled(BaseMenuItem)(
    ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    cursor: default;
    user-select: none;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${menuItemClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    }
  
    &.${menuItemClasses.disabled} {
      color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${menuItemClasses.disabled}) {
      background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[50]};
      color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
    }
    `,
);

const MenuButton = styled(BaseMenuButton)(
    ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 600;
    color: white;
    transition: all 150ms ease;
    cursor: pointer;
    background: ${theme.palette.mode === 'dark' ? grey[900] : blue[200]};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : blue[200]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[700]};
  
    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }
  
    &:active {
      background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
    }
  
    &:focus-visible {
      box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
      outline: none;
    }
    `,
);

export default Home;