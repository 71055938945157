import React from "react";
import { useLocation } from "react-router-dom";
import axios, { privateAxios } from "../api/axios";
import useAuth from "../hooks/useAuth";

const ManageUsers = () => {

    const { state } = useLocation();
    const { auth } = useAuth();


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await privateAxios.post("/admin/delete",
                JSON.stringify({ delete: state.user }), {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
        } catch (err) {
            console.log(err);
        }

    }

    return (
        <div className="position-absolute top-40 start-50 translate-middle form container bg-secondary text-light w-auto h-auto p-4 rounded-1">
            <h1>Manage {state.user}</h1>
            <form action="submit" onSubmit={(e) => handleSubmit(e)}>
                <div className="p-1">
                    <button className="btn btn-danger">Delete</button>
                </div>
            </form>
        </div>
    )

}


export default ManageUsers;