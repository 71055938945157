export const lang  = [
    { lang: "EN", selection: {
        "total": "Total meters",
        "totalPlanks": "Total boards",
        "m/min": "Meters/Min",
        "data": "Data",
        "adminPanel": "Admin Panel",
        "users": "Users",
        "currentSpeed": "Current Speed",
        "totalWorkTime": "Total Working Time",
        "workTime": "Work Time",
        "idleTime": "Idle Time",
        "avgSpeed": "Average Speed",
    } },
    { lang: "LV", selection: {
        "total": "Kopējie metri",
        "totalPlanks": "Sagatave(s)",
        "m/min": "Metri/Min",
        "data": "Dati",
        "adminPanel": "Administratora panelis",
        "users": "Lietotāji",
        "currentSpeed": "Pašreizējais ātrums",
        "totalWorkTime": "Kopējais darba laiks",
        "workTime": "Darba laiks",
        "idleTime": "Neaktīvais laiks",
        "avgSpeed": "Vidējais ātrums",
    } },
    { lang: "DE", selection: {
        "total": "Gesamt",
        "totalPlanks": "Insgesamt Planken",
        "m/min": "Meters/Min",
        "data": "Daten",
        "adminPanel": "Administrationsmenü",
        "users": "Benutzer",
        "currentSpeed": "Momentane Geschwindigkeit",
        "totalWorkTime": "Gesamtarbeitszeit",
        "workTime": "Arbeitszeit",
        "idleTime": "Leerlaufzeit",
        "avgSpeed": "Durchschnittsgeschwindigkeit",
    } },
]

export const getLang = (id?: string): number => {
    id = id || localStorage.getItem("lang") || "en";
    for (var i = 0; i < lang.length; i++) {
        if (lang[i]["lang"].toLowerCase() === id.toLowerCase()) {
            return i;
        }
    }
    return 0;
}