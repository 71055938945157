import RefreshToken from "../hooks/refreshToken";
import useAuth from "../hooks/useAuth";
import { Outlet } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Home from "./Home";
import { Skeleton } from "../components/ui/skeleton"

const PersistLogin = () => {
    const [loading, setLoading] = useState(true);
    const refreshToken = RefreshToken();
    const { auth } = useAuth();

    useEffect(() => {
        const verifyToken = async () => {
            try {
                await refreshToken();
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        }

        !auth?.accessToken ? verifyToken() : setLoading(false);

    }, []);

    return (
        <div>
            {loading ? <div>
                <Home />
                <text style={{
                    fontSize: "2em",
                }}>
                    Loading...
                </text>
            </div> : <Outlet />}
        </div>
    )

}

export default PersistLogin;