import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const SiteMissing = () => {
    
    const nav = useNavigate();

    useEffect(() => {
        nav("/login", {replace: true});    
    }, []);

    return (
        <div>
        </div>
    )
}

export default SiteMissing;