import { privateAxios } from "../api/axios";
import React, { useEffect } from "react";
import refreshToken from "./refreshToken";
import useAuth from "./useAuth";

const AxiosPrivate = () => {
    const refresh = refreshToken();
    const { auth } = useAuth();

    useEffect(() => {

        const requestIntercept = privateAxios.interceptors.request.use(config => {
            if (!config.headers['authorization']) {
                config.headers['authorization'] = 'Bearer ' + auth?.accessToken;
            }
            return config;
        }, (err) => Promise.reject(err));

        const responseIntercept = privateAxios.interceptors.response.use(response => response, async (err) => {
            const prevReq = err?.config;
            if (err?.response?.status === 403 && !prevReq?.sent) {
                prevReq.sent = true;
                const newAccessToken = await refresh();
                prevReq.headers['authorization'] = 'Bearer ' + newAccessToken;
                return privateAxios(prevReq);
            }
            return Promise.reject
        });
        return () => {
            privateAxios.interceptors.request.eject(requestIntercept);
            privateAxios.interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh]);

    return privateAxios;
}

export default AxiosPrivate;