import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import axios, { privateAxios } from "../api/axios";
import useAuth from "../hooks/useAuth";
import ErgoTak from "../assets/ErgoTak.png"
import { Separator } from "../components/ui/separator";
import { Accordion, AccordionItem, AccordionContent, AccordionTrigger } from "../components/ui/accordion";
import video from "../assets/background.mp4";
import preview from "../assets/preview.png";
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../components/ui/form"
import { Input } from "../components/ui/input"
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "../components/ui/button";
import { IconButton } from "@radix-ui/themes";
import East from "@mui/icons-material/East";

const Login = () => {
    const { setAuth } = useAuth();
    const form = useForm();
    const nav = useNavigate();
    const formSchema = z.object({
        username: z.string().min(3).max(20),
        password: z.string().min(3).max(20),
    });

    useEffect(() => {
        const validRegistry = async () => {
            try {
                const response = await privateAxios.post("/check");
                if (response?.data?.auth === true) {
                    nav("/home", { replace: true });
                }
            } catch (ignored) {

            }
        };

        validRegistry();
    }, []);


    async function handleSumbit(values: z.infer<typeof formSchema>) {
        const { username, password } = values;
        try {
            const response = await axios.post("/login",
                JSON.stringify({ username, password }), {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
            const res = await response?.data;
            const isAdmin = res?.isAdmin;
            const companyAdmin = res?.companyAdmin;
            const maxMM = res?.maxMM;
            setAuth({ username, isAdmin, companyAdmin, maxMM });
            res?.auth === true ? nav("/home", { replace: true }) : toast.error("Incorrect Credentials");
        } catch (err) {
            if (err.response?.status === 401) return;
            toast.error(`Error code ${err.response?.status}. \nPlease contact an ODA Instruments representative!`)
        }

    }

    const loginForm = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            username: "",
            password: ""
        }
    });

    const formRenderer = (
        <div>
            <Toaster position="top-center" reverseOrder={true} />
            <div className="position-absolute container-fluid w-100 h-49" style={{ objectFit: "cover" }}>
                <video autoPlay loop muted id="video"
                    style={{
                        position: "relative",
                        paddingTop: "5%",
                        overflow: "hidden",
                        width: "100%",
                        height: "100%",
                        zIndex: -1,
                        objectFit: "cover"
                    }}>
                    <source src={video} type="video/mp4" />
                </video>
            </div>

            <div className="position-absolute top-50 start-50 translate-middle">
                <div className="position-relative" style={{
                    paddingTop: "60px",
                }}>
                    <img src={ErgoTak} width={"209px"} height={"88px"} alt="Logo" />
                </div>
                <Form {...loginForm}>
                    <form onSubmit={form.handleSubmit(handleSumbit)} className="space-y-8">
                        <FormField
                            control={form.control}
                            name="username"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input type="" placeholder="Username" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="password"
                            render={({ field }) => (
                                <FormItem>
                                    <FormMessage />
                                    <FormControl>
                                        <Input type="password" placeholder="Password" {...field} />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <IconButton className="position-relative start-50 translate-middle" type="submit">
                            Enter <East />
                        </IconButton>
                    </form>

                </Form>

            </div>

            <footer className="position-absolute container-fluid top-90" style={{
                paddingTop: "30px"
            }}>
                <Separator className="w-100" />
                <Accordion type="single" collapsible>
                    <AccordionItem value="item-2">
                        <AccordionTrigger>Contact Us</AccordionTrigger>
                        <AccordionContent>
                            <div>
                                <p>For any inquiries, please contact us at:</p>
                                <p>Phone: +371 266 766 22</p>
                                <p>Email: info@odainstruments.lv</p>
                                <p>Web-admin Email: renars@odainstruments.lv</p>
                            </div>
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            </footer>

        </div>
    )

    return (
        <div className="login">
            <div className="login-form">
                {formRenderer}
            </div>
        </div>
    );
}

export default Login;